<script lang="ts">
  // Components
  import { Icon } from '$lib/components/atoms/Icon';
</script>

<span class="badge-best-seller">
  <span class="badge-icon"><Icon size="full" icon="star-fill" /></span>
  <span class="badge-text">Best Seller</span>
</span>

<style type="text/scss" lang="scss" scoped>
  .badge-best-seller {
    @apply flex w-full items-center justify-center;
    @apply px-3;
    @apply h-8;
    @apply bg-status-best-seller bg-opacity-70;
    @apply text-black;
  }

  .badge-text {
    @apply font-sans font-semibold text-xs uppercase tracking-wide;
  }

  .badge-icon {
    @apply inline-flex w-3 h-3;
  }

  .badge-icon + .badge-text {
    @apply ml-1;
  }
</style>
